<template>
    <div class="wrapper" v-if="isShow">
	    <v-navigation-drawer ref="drawer" permanent stateless :width="navigation.width" :class="{ 'sidebar-show' : showAnimation, 'sidebar-hidden' : hiddenAnimation }">
            <SideBar class="container-sidebar" />
	    </v-navigation-drawer>
        <div class="main">
            <nav class="navbar navbar-expand">
                <a class="sidebar-toggle d-flex me-2 mt-2 mb-2" @click="fnTogged">
                    <i class="fas fa-bars fa-2x"></i>
                </a>
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <span class="navbar-text text-white">{{ title }}</span>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item">

                    </li>
                </ul>
                <!-- <div class="text-right col-6 justify-content-end  m-0 p-0">
                    <a @click="page_previous()" v-if="(route == 'dashboard.index' && pagePrevious != undefined)">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                    </a>
                </div> -->
            </nav>
            <app-header :title="title" :explain="explain" />
            <main class="content">
                <div class="container-fluid" :class="{cssPageDashboard: isClass}">
                    <slot />
                </div>
            </main>
            <!-- <footer class="footer">&copy;{{year}}</footer> -->
        </div>
    </div>
</template>

<script>
import AppHeader from "@/components/Header";
import {MENU} from "@/common/constants";
import SideBar from "../components/layout/SideBar";

export default {
    name: "DefaultLayout",

    components: {
        SideBar,
        // AppSidebar,
        AppHeader,
    },

    data() {
        return {
            isClass: false,
            menu: MENU,
            togged: window.innerWidth <= 1024 ? true : false,
            year: (new Date).getFullYear(),
            isShow: false,
	        navigation: {
		        width: window.innerWidth <= 500 ? "50%" : "20%",
		        borderSize: 3
	        },
	        showAnimation: false,
	        hiddenAnimation: false,
	        clickCount: 0,
        };
    },

    created() {
        if(window.localStorage.getItem("show_menu")) {
            this.isShow = true
        } else {
            this.isShow = false
        }
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize);
        });
	    this.setBorderWidth();
	    this.setEvents();
	    this.showAnimation = true;
	    this.hiddenAnimation = false;
	    $(".navigation").one("animationend", () => {
		    this.showAnimation = false;
	    });
    },

    computed: {
        title() {
            return this.$route.meta.title;
        },

        explain() {
            return this.$route.meta.explain;
        },
	
	    cssWidth() {
		    return {
			    "widthAnimation": this.navigation.width,
		    }
	    },
    },

    methods: {
        fnTogged() {
	        this.hiddenAnimation = !this.hiddenAnimation;
	        if (this.hiddenAnimation) {
		        this.hiddenAnimation = true;
	        } else {
                this.navigation.width = window.innerWidth <= 500 ? "50%" : "20%";
		        this.hiddenAnimation = false;
	        }
        },

        onResize() {
            this.togged = window.innerWidth === 1024;
	        if (window.innerWidth <= 500) {
		        this.hiddenAnimation = true;
	        }
        },
	
	    setBorderWidth() {
		    let i = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
		    i.style.width = this.navigation.borderSize + "px";
		    i.style.cursor = "ew-resize";
	    },
	    setEvents() {
		    const minSize = this.navigation.borderSize;
		    const el = this.$refs.drawer.$el;
		    const drawerBorder = el.querySelector(".v-navigation-drawer__border");
		    const direction = el.classList.contains("v-navigation-drawer--left")
			    ? "left"
			    : "right";
			
            const setHiddenAnimation = (hiddenAnimation) => {
                this.hiddenAnimation = hiddenAnimation;
            }
		
		    function resize(e) {
                if (e.clientX <= 10) {
	                setHiddenAnimation(true)
                }
			    document.body.style.cursor = "ew-resize";
			    let f =
				    direction === "left"
					    ? document.body.scrollWidth - e.clientX
					    : e.clientX;
			    el.style.width = f + "px";
		    }
		
		    drawerBorder.addEventListener(
			    "mousedown",
			    (e) => {
				    if (e.offsetX < minSize) {
					    el.style.transition = "initial";
					    document.addEventListener("mousemove", resize, false);
				    }
			    },
			    false
		    );
		
		    document.addEventListener(
			    "mouseup",
			    () => {
				    el.style.transition = "";
				    this.navigation.width = el.style.width;
				    document.body.style.cursor = "";
				    document.removeEventListener("mousemove", resize, false);
			    },
			    false
		    );
	    }
    },
    watch: {
        "$route.path": {
            handler(newVal) {
                if (newVal === "/dashboard") {
                    this.isClass = true
                }else {
                    this.isClass = false
                }
            },
            immediate: true
        },
	
	    isShowNavigation() {
		    this.hiddenAnimation = false;
		    this.showAnimation = true;
		
		    $(".navigation").one("animationend", () => {
			    this.showAnimation = false;
		    });
	    },
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
};
</script>
<style lang="sass" scoped>
    .wrapper
        & .container-sidebar
            min-width: 20%
        .main
            flex: 1
    .cssPageDashboard
        height: 100% !important
    .sidebar-show
        animation: show 0.5s ease forwards
    .sidebar-hidden
        animation: hidden 0.5s ease forwards
    @media (max-width: 1025px)
        .navigation
            transform: translate(0%) !important
        .v-navigation-drawer
            overflow: visible
        .v-navigation-drawer--close
            visibility: unset
    @keyframes hidden
        from
            width: var(widthAnimation)
        to
            width: 0
    @keyframes show
        from
            width: 0
        to
            width: var(widthAnimation)
</style>
