<template>
    <header class="m-2  p-0">
        <div class="row  m-0 p-0">
            <!-- <div class="col-6 m-0 p-0 pb-1">
                {{ title }}
            </div> -->
            <div class="text-right col-12 justify-content-end  m-0 p-0">
                <a @click="page_previous()" v-if="(route == 'dashboard.index' && pagePrevious != undefined)">
                    <i class="fa fa-undo" aria-hidden="true"></i>
                </a>
            </div>
        </div>
    </header>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
    name: "Header",

    props: ["title", "explain"],

    data() {
        return {
            route: null,
        }
    },
    computed: {
        ...mapGetters({
            pagePrevious: "dashboard/getPagePrevious"
        })
    },
    watch: {
        "$route.name"() {
            this.route = this.$route.name;
        }
    },
    mounted() {
        this.route = this.$route.name;
    },

    methods: {
        page_previous() {
            this.$router.push({ name: this.pagePrevious.page });
        }
    }
};
</script>
