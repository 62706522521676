<template>
    <v-navigation-drawer class="container-nav-sidebar"
                         width="100%"
                         permanent
                         stateless
    >
      <div class="sidebar-fixed-top">

        <!-- <v-list dense
                nav
                rounded
                class="nav-sidebar-footer"
        > -->
          <!-- <v-list-item>
            <v-list-item-content>
              {{ $t('login.login') }}
            </v-list-item-content>
          </v-list-item> -->
          <!-- <v-list-item>
            <v-list-item-content>
              [{{ String(this.user?.id).padStart(7, "0") }}]{{ userName }} ({{ userOccupationText }})
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <router-link :to="{ name: 'change-pass' }">{{ $t('layout.change_password') }}</router-link>
            </v-list-item-content>
              <Button size="small" color="warning" @click="isShowDialog = true">{{ $t('layout.logout') }}</Button>
          </v-list-item> -->
        <!-- </v-list> -->
        <!-- <v-divider></v-divider> -->


        <div>
          <v-list dense
                  nav
                  expand
                  rounded
                  class="nav-sidebar"
          >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ PRODUCT_NAME }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ TENANT_NAME }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              [{{ String(this.user?.id).padStart(7, "0") }}]{{ userName }} ({{ userOccupationText }})
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <router-link :to="{ name: 'change-pass' }">{{ $t('layout.change_password') }}</router-link>
            </v-list-item-content>
              <Button size="small" color="warning" @click="isShowDialog = true">{{ $t('layout.logout') }}</Button>
          </v-list-item>
          <v-divider></v-divider>


            <div v-for="menuItem in listMenu" :key="menuItem.id">
              <div v-if="Object.values(menuItem.status).some(ai => Object.values(menuItem.permissions).includes(ai))">
                <template v-if="menuItem.position === 'header'">
                  <v-list-item
                      v-if="!menuItem.children || !menuItem.children.length"
                      :to="menuItem.url"
                      :key="menuItem.title"
                      link
                  >
                    <v-list-item-content @click="groupOpened = false">
                      {{ menuItem.title }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-group
                      v-else
                      :key="menuItem.title"
                      :group="menuItem.url"
                      :value="menuItem.active"
                  >
                    <template #activator>
                      <v-list-item two-line>
                        <v-list-item-content>
                          {{ menuItem.title }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <div v-for="subMenuItem in menuItem.children" :key="subMenuItem.id">
                      <div
                          v-if="Object.values(menuItem.status).some(ai => Object.values(subMenuItem.permission_children).includes(ai))">
                        <v-list-item
                            v-if="!subMenuItem.external_link"
                            class="ml-5"
                            :key="subMenuItem.title"
                            :to="menuItem.url + subMenuItem.url"
                            link
                            two-line
                        >
                          <v-list-item-content>
                            {{ subMenuItem.title }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="ml-5" two-line v-if="subMenuItem.external_link" :href="subMenuItem.url" target="_blank">{{ subMenuItem.title }}</v-list-item>
                      </div>
                    </div>
                  </v-list-group>
                </template>
              </div>
            </div>
          </v-list>
        </div>
      </div>
      <Dialog :maxWidth="600" :isShow="isShowDialog" persistent>
        <v-card>
          <v-container>
            <v-card-title>
              確認
            </v-card-title>
            <div class="m-3">
              ログアウトします。よろしいですか？。
            </div>
            <v-card-actions
                class="
                                  d-flex
                                  align-content-center
                                  justify-content-between
                                  mr-2
                                  ml-2
                            "
            >
              <button
                  type="button"
                  class="btn btn-secondary"
                  @click="isShowDialog = false">
                キャンセル
              </button>
              <button
                  type="button"
                  class="btn btn-primary"
                  @click="logout">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
    </v-navigation-drawer>
</template>

<script>
import SideMenu from "../../config/side-menu.json";
import { mapGetters, mapMutations } from "vuex";
import AuthRepository from "@/services/api/AuthRepository";
import ApiService from "@/services/api/api.service";
import Button from "../common/Button";
import Dialog from "@/components/common/Dialog";
import { PRODUCT_NAME, ENVS } from "@/common/constants";

export default {
    name: "SideBar",
    components: { Button, Dialog },
    data() {
        return {
            listMenu: [],
            isPermission: false,
            isUserPermission: [],
            isShowDialog: false,
            PRODUCT_NAME,
            TENANT_NAME: ENVS.TENANT_NAME,
        }
    },
    created() {
        if (SideMenu && SideMenu.length) {
            this.listMenu = SideMenu.map(menuItem => {
                if(menuItem.title == "データ管理"){
                    return {
                        ...menuItem,
                        active: false,
                    }
                }
                if(menuItem.title == "その他") {
                    menuItem.children.forEach(v => {
                        if(v.title == "掲示版") {
                            v.url = ENVS.EXTERNAL_LINK_BULLETIN_BOARD
                            v.external_link = true
                        }
                        return v;
                    });
                }
                return {
                    ...menuItem,
                    active: !!this.$route.fullPath.includes(menuItem.url)
                }
            })
        }
        if (this.user) {
            AuthRepository.checkAuth().then((res) => {
                const handlePermissions = res.data.user.user_sys_auth_div;
                const dataUserRole = handlePermissions.split(",");
                dataUserRole.forEach(element => {
                    this.isUserPermission.push(parseInt(element));
                });
                this.handleUserPermissions(this.listMenu);
            });
        }
    },
    computed: {
        userName() {
            return this.user && this.user.user_name ? this.user.user_name : "User Name"
        },
        userOccupationText() {
            return this.user?.user_occupation_text
        },
        ...mapGetters({
            user: "common/getUser"
        })
    },
    watch: {
        $route(to) {
            if (to && to.fullPath && this.listMenu) {
                this.listMenu = this.listMenu.map(menuItem => {
                    if(menuItem.title == "データ管理"){
                        return {
                            ...menuItem,
                            active: false,
                        }
                    }
                    return {
                        ...menuItem,
                        active: !!to.fullPath.includes(menuItem.url)
                    }
                })
            }
            this.$forceUpdate()
        }
    },
    methods: {
        ...mapMutations({
            setUser: "common/setUser",
        }),
        handleUserPermissions(role) {
            role.forEach(element => {
                if (Object.values(this.isUserPermission).some(ai => Object.values(element.permissions).includes(ai))) {
                    element.status = Object.values(this.isUserPermission);
                }
                element.children.forEach(x => {
                    if (Object.values(this.isUserPermission).some(ai => Object.values(x.permission_children).includes(ai))) {
                        element.status = Object.values(this.isUserPermission);
                    }
                });
            });
        },
        logout() {
            ApiService.post("logout").then(() => {
                this.$router.replace("login");
                this.setUser({})
                localStorage.clear();
                this.isShowDialog = false;
                this.$toast.open({
                    message: "ログアウトしました。",
                    type: "success",
                });
            });
        }
    }
};
</script>

<style lang="sass">
.container-nav-sidebar
  & .v-navigation-drawer__content
    display: flex
    flex-direction: column
    justify-content: space-between
.nav-sidebar
  & .v-list-item
    cursor: pointer
  & .v-list-group
    margin-bottom: 10px
    & .v-list-item
      cursor: pointer
.nav-sidebar-footer
  & .v-list-item
    &:nth-child(2)
      margin-left: 20px
    &:last-child
      margin-left: 40px
.sidebar-fixed-top
  position: sticky
  top: 0
  z-index: 999
  background-color: white
  & .v-divider
    margin-bottom: 0
.sidebar-fixed-bottom
  position: sticky
  bottom: 0
  z-index: 999
  background-color: white
  & .v-divider
    margin-top: 0
</style>
